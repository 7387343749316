import React, { useEffect, useState } from 'react';

import './ResetPassword.css';
import { PASSWORD_RESET_DONE_URL } from '../../shared/constants';
import ErrorLink from '../ErrorLink/ErrorLink';
import { parseUrl } from '../../services/ParseUrl';
import { fetchRequest } from '../../services/FetchRequest';
import Loader from '../Loader/Loader';
import { environment } from '../../env/env';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [token, setToken] = useState('');
    const [uidb64, setUidb64] = useState('');
    const [validToken, setValidToken] = useState(false);

    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const queryParams = parseUrl(window.location.href);
        setToken(queryParams.token);
        setUidb64(queryParams.uidb64);
        setValidToken(queryParams.token_valid === 'True' && !!queryParams.token && !!queryParams.token);
    }, []);

    const handleChangePasswordProcess = async (event) => {
        event.preventDefault();

        if (password !== password2) {
            setErrorMessage('Passwords do not match');
            setTimeout(() => setErrorMessage(''), 5000);
        } else {
            setLoading(true);
            const url = `${environment.BASE_URL}/${PASSWORD_RESET_DONE_URL}`;

            try {
                const passwordResetResult = await fetchRequest('PATCH', url, { password, token, uid_b64: uidb64 });
                setSuccessMessage(passwordResetResult.message);
            } catch (error) {
                setErrorMessage(error.message);
                setTimeout(() => setErrorMessage(''), 5000);
            }
        }
        setLoading(false);

    };

    const passwordInputChange = (event) => {
        event.preventDefault();
        setPassword(event.target.value);
        setDisabled(!password || !password2);
    };

    const password2InputChange = (event) => {
        event.preventDefault();
        setPassword2(event.target.value);
        setDisabled(!password || !password2);
    };

    const renderComponent = () => (
        <React.Fragment>
            <h2 className={'Main_Title'}>Enter a new password</h2>
            <form className={'Reset_Password__Form'}>
                <input className={'Reset_Password__Form_Field'}
                       type="password"
                       value={password}
                       placeholder="New password"
                       onChange={passwordInputChange}/>
                <input className={'Reset_Password__Form_Field'}
                       type="password"
                       value={password2}
                       placeholder="Confirm new password"
                       onChange={password2InputChange}/>

                <div className={'Button_Container'}>
                    {loading ? <div className={'Reset_Password__Loader_Wrapper'}><Loader/></div> : ''}
                    <button disabled={disabled}
                            onClick={handleChangePasswordProcess}>Change password
                    </button>
                    {!!errorMessage ? <div className={'Error_Message'}>{errorMessage}</div> : ''}
                    {!!successMessage ? <div className={'Success_Message'}>{successMessage}</div> : ''}
                </div>
            </form>
        </React.Fragment>);

    return (
        <div className={'Small_Container'}>
            {validToken ? renderComponent() :
                <ErrorLink message={'The link is invalid, please try again to reset the password from the extension'}/>}
        </div>);
};

export default ResetPassword;

