import React from 'react';

const Main = () => {
    return (
        <div className={'Small_Container'}>
            <h2 className={'Main_Title'}>Welcome to CoSupport AI</h2>
        </div>
    );
};

export default Main;
