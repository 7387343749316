export const fetchRequest = async (method, url, data, headers) => {
    if (!headers) {
        headers = {
            Accept        : 'application/json, application/xml, text/plain, text/html, *.*',
            'Content-Type': 'application/json; charset=utf-8',
        }
    } else {
        headers = {
            Accept        : 'application/json, application/xml, text/plain, text/html, *.*',
            'Content-Type': 'application/json; charset=utf-8',
            ...headers,
        }
    }

    let isError = false;

    const params = {
        method,
        headers,
    };

    if (data) {
        params.body = JSON.stringify(data);
    }
    return fetch(url, params).then(response => {
        isError = !response.ok;
        if (!response.ok) {
            return response.json();
        } else {
            if (response.statusText === "No Content") {
                return response.ok;
            } else {
                return response.json();
            }
        }
    }).then(response => {
        if (!isError) {
            return response;
        } else {
            let errorMessage = '';
            JSON.stringify(response, (key, value) => {
                if (typeof value === 'string') {
                    errorMessage += errorMessage ? `, ${value}` : value;
                }
                return value;
            });
            throw new Error(errorMessage);
        }
    });
}
