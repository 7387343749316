import React, { useEffect, useState } from 'react';

import './EmailVerify.css';
import { parseUrl } from '../../services/ParseUrl';
import ErrorLink from '../ErrorLink/ErrorLink';

import checkErrorImg from '../../assets/img/check-error.svg';
import checkSuccessImg from '../../assets/img/check-success.svg';
import Loader from '../Loader/Loader';

const EmailVerify = () => {
    const [successResult, setSuccessResult] = useState('loading');

    useEffect(() => {
        const { success } = parseUrl(window.location.href);
        setSuccessResult(success);

    }, []);

    const renderLoading = () => (
        <React.Fragment>
            <div className={'Email_Verify__Loader_Wrapper'}><Loader/></div>
            <p className={'Email_Verify__Result_Text_Container'}>
                <img src={checkSuccessImg} alt="Success: "/>
                <span className={'Email_Verify__Result_Text'}>Email was verified</span>
            </p>
        </React.Fragment>
    );

    const renderSuccess = () => (
        <React.Fragment>
            <p className={'Email_Verify__Result_Text_Container'}>
                <img src={checkSuccessImg} alt="Success: "/>
                <span className={'Email_Verify__Result_Text'}>Email was verified</span>
            </p>
        </React.Fragment>
    );

    const renderError = () => (
        <React.Fragment>
            <p className={'Email_Verify__Result_Text_Container'}>
                <img src={checkErrorImg} alt="Error: "/>
                <span className={'Email_Verify__Result_Text'}>Email verified failed</span>
            </p>
        </React.Fragment>
    );

    const switchRenderVerifyResult = () => {
        switch (successResult) {
            case 'true/':
                return renderSuccess();
            case 'false/':
                return renderError();
            default:
                return renderLoading();
        }
    };

    const renderComponent = () => (
        <React.Fragment>
            <h2 className={'Main_Title'}>Email verify</h2>
            <div className="Email_Verify">
                {switchRenderVerifyResult()}
            </div>
        </React.Fragment>
    );


    return (
        <div className={'Small_Container'}>
            {!!successResult ? renderComponent() :
                <ErrorLink message={'The link is invalid, please try again to sign up from the extension'}/>}
        </div>
    );
};

export default EmailVerify;
