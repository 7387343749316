import React from 'react';

import './ErrorLink.css';

const ErrorLink = ({message}) => {
    return (
        <React.Fragment>
            <h2 className={'Main_Title'}>Something went wrong</h2>
            <p className={'Invalid_Token__Error_Message'}>{message}</p>
        </React.Fragment>
    );
};

export default ErrorLink;
