import React from 'react';

import './UninstallExtension.css';

const UninstallExtension = () => {
    return (<React.Fragment>
        <h2 className={'Main_Title'}>Thank you for using our extension</h2>
    </React.Fragment>);
};

export default UninstallExtension;
