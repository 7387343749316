import './App.css';
import React from 'react';

import {Route, Routes} from 'react-router-dom';
import ResetPassword from './components/ResetPassword/ResetPassword';
import EmailVerify from './components/EmailVerify/EmailVerify';

import LogoImg from './assets/img/icon-128.png';
import Main from './components/Main/Main';
import UninstallExtension from "./components/UninstallExtension/UninstallExtension";

const App = () => {

    return (
        <div className={'App_Container'}>
            <img className={'App_Logo'} src={LogoImg} alt="logo"/>
            <Routes>
                <Route path={'reset-password'} element={<ResetPassword/>}/>
                <Route path={'email-verify'} element={<EmailVerify/>}/>
                <Route path={'uninstall-extension'} element={<UninstallExtension/>}/>
                <Route path={'/'} element={<Main/>}/>
            </Routes>
        </div>
    );
};

export default App;
